<div style="padding: 24px;">
  <h1 style="text-align: center" mat-dialog-title>{{ "modifyPassword" | translate | capitalizeFirstLetter }}</h1>
  <p [innerHTML]="'passwordValidationRules' | translate"></p>
  <mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onConfirm()">
      <ion-row>
        <ion-col>
          <mat-form-field appearance="outline" color="accent" style="width: 100%">
            <mat-label>{{ "newPassword" | translate | capitalizeFirstLetter }}</mat-label>
            <input autocomplete="new-password" matInput type="password" placeholder="Nouveau mot de passe"
              formControlName="newPassword" required />
          </mat-form-field>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <mat-form-field appearance="outline" color="accent" style="width: 100%">
            <mat-label>{{ "confirmPassword" | translate | capitalizeFirstLetter }}</mat-label>
            <input autocomplete="new-password" matInput type="password" placeholder="Confirmer mot de passe"
              formControlName="confirmPassword" required />
          </mat-form-field>
        </ion-col>
      </ion-row>
    </form>
  </mat-dialog-content>

  <button type="submit" mat-button style="
    display: block;
    margin-left: auto;
    margin-right: auto;
    " [disabled]="!form.valid" (click)="onConfirm()" [mat-dialog-close]="form" cdkFocusInitial>
    {{ "confirm" | translate | capitalizeFirstLetter }}
  </button>
</div>