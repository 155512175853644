import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingController } from '@ionic/angular';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss'],
})
export class EditPasswordComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _translate: TranslateService,
    private sharedService: SharedService,
    private loadingCtrl: LoadingController,
    private _graphql: GraphqlService,
  ) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup(
      {
        newPassword: new UntypedFormControl("", {
          validators: [Validators.required],
        }),
        confirmPassword: new UntypedFormControl("", {
          validators: [Validators.required],
        }),
      },
      {
        validators: (control) => {
          if (control.value.newPassword !== control.value.confirmPassword) {
            control.get('confirmPassword').setErrors({ notSame: true });
          }
          return null;
        },
      }
    );
  }

  onConfirm() {
    this.loadingCtrl
      .create({
        message: this._translate.instant('modifying'),
      })
      .then((loadingEl) => {
        loadingEl.present();
        this._graphql.resetPassword(this.form.value.newPassword)
          .pipe(take(1))
          .subscribe(() => {
            this.sharedService.makeToast(
              this._translate.instant('pwdChangedSuccess'),
              '',
              'success'
            );
            loadingEl.dismiss();
          },
            (error) => {
              loadingEl.dismiss();
              this.sharedService.makeToast(
                this._translate.instant("errorOccured:"+error),
                '',
                'danger'
              );
            })
      });
  }
}
